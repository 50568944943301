<style scoped>
.change-icon{
    margin-top: 50px;
    text-align: center;
}
</style>

<template>
  <div>
      <div v-show="currentContent===1">
        <h3 class="text-center p-b-10">配置订单基本信息</h3>
        <Form ref="orderBaseData" :model="orderBaseData" :rules="orderRuleValidate" label-position="top" style="height:100%">
          <Row :gutter="18">
            <i-col span="10">
              <FormItem label="原订单客户">
                <span class="m-r-10">{{orderInfo.advertiserName}}</span>
              </FormItem>
              <FormItem label="原投放品牌">
                <span>{{orderInfo.brandName}}</span>
              </FormItem>
              <FormItem label="原联系人">
                <span>{{orderInfo.contactName}}</span>
              </FormItem>
              <FormItem label="原备注">
                <span>{{orderInfo.remark}}</span>
              </FormItem>
            </i-col>
            <i-col span="4" class="change-icon">
              <Icon type="md-arrow-round-forward" size="80" color="green"></Icon>
            </i-col>
            <i-col span="10">
              <FormItem label="新订单客户"  prop="advertiserId">
                <Select v-model="orderBaseData.advertiserId" clearable placeholder="请选择一个客户" @on-change="customerChange(1)">
                  <Option v-for="(customer,index) in customers"  :value="customer.value"  :key="index">{{customer.label}}</Option>
                </Select>
              </FormItem>
              <FormItem label="新投放品牌"  prop="brandId">
                <Select v-model="orderBaseData.brandId"  placeholder="请选择一个投放品牌">
                  <Option v-for="brand in brands" :value="brand.id"  :key="brand.id">{{brand.name}}</Option>
                </Select>
              </FormItem>
              <FormItem label="新联系人"  prop="contactId">
                <Select v-model="orderBaseData.contactId" placeholder="请选择一个联系人">
                  <Option v-for="contact in contacts" :value="contact.id" :key="contact.id">{{contact.name}}</Option>
                </Select>
              </FormItem>
              <FormItem label="新备注"  prop="remark">
                <Input v-model="orderBaseData.remark" placeholder="请填写备注。" />
              </FormItem>
            </i-col>
          </Row>
        </Form>
        <Row class="p-t-5">
          <i-col span='24'>
            <Button size="small" type="primary" class="right m-r-10" @click="NextStep">下一步</Button>
          </i-col>
        </Row>
      </div>

      <div v-show="currentContent===2">
        <h3 class="text-center p-b-10">设置需要调整的资源和档期</h3>

        <div style="padding: 20px" class="m-b-20 transaction-content">
          <Row class="m-b-10">
            <i-col span="12" class="m-b-10">
              <Badge text="new" type="success" class="p-r-5"><span class="title">订单客户</span> </Badge>
              <span class="m-l-20">{{orderBaseData.advertiserName}}</span>
            </i-col>
            <i-col span="12" class="m-b-10">
              <Badge text="new" type="success" class="p-r-5"><span class="title">投放品牌</span></Badge>
              <span class="m-l-20">{{orderBaseData.brandName}}</span>
            </i-col>
            <i-col span="12" class="m-b-10">
              <Badge text="new" type="success" class="p-r-5"><span class="title">联系人</span></Badge>
              <span class="m-l-20">{{orderBaseData.contactName}}</span>
            </i-col>
          </Row>
          <Row>
            <i-col span="24">
              <Badge text="new" type="success" class="p-r-5"><span class="title">订单备注</span></Badge>
              <span class="m-l-20">{{orderBaseData.remark}}</span>
            </i-col>
          </Row>
        </div>

        <div v-for="category in categories" :key="category.categoryId">
          <Timeline class="p-t-20">
              <TimelineItem v-for="(schedule,i) in category.schedules" :key="i">
                  <p class="time">
                    <span>{{category.category}} {{formatSchedule(schedule.oldSchedule.startDate, schedule.oldSchedule.endDate)}}</span>

                    <span v-show="schedule.newSchedules.length">
                      <Icon type="md-arrow-round-forward" size='18' color="#44bd32" class="m-l-5 m-r-5"/>
                      <Tag type="border" color="black" v-for="(item,itemIndex) in schedule.newSchedules" :key="itemIndex">{{formatSchedule(item.startDate,item.endDate)}}</Tag>

                      <a class="warning m-l-5" @click="CancelCopySchedule(category.categoryId,schedule)">还原档期</a>
                    </span>

                    <a class="m-l-10" @click="handleChangeCopySchedule(category.categoryId,schedule)">调整复制档期</a>
                  </p>
                  <CheckboxGroup v-model="schedule.checkProducts">
                      <Checkbox v-for="product in schedule.productItem" :key="product.productId" :label="product.productId">{{product.productName}}({{product.quantity+product.productUnit}})</Checkbox>
                  </CheckboxGroup>
              </TimelineItem>
          </Timeline>
        </div>

        <Row class="p-t-10">
           <i-col span='12'>
            <Button size="small" type="info" @click="currentContent=1">上一步</Button>
          </i-col>
          <i-col span='12'>
            <Button size="small" type="success" class="right" :disabled="Btndisabled" @click="ConfirmCopyOrder">确认</Button>
          </i-col>
        </Row>
      </div>

      <Modal title="调整资源档期" v-model="modelChangeSchedule" :mask-closable="false" width="800">
        <Row class="p-b-10">
          <i-col class="p-b-5">
            当前档期：<Tag v-if="checkScheduleProduct!==null" type="border" color="black">{{formatSchedule(checkScheduleProduct.oldSchedule.startDate,checkScheduleProduct.oldSchedule.endDate)}}</Tag>
          </i-col>
          <i-col span="24">
            <span>档期类型：</span>
            <RadioGroup size="small" v-model="ScheduleStandard" type="button">
              <Radio v-for="item in scheduleTypes" :key="item.id" :label="item.id">{{item.name}}</Radio>
            </RadioGroup>
          </i-col>
        </Row>

        <Row v-if="ScheduleStandard===1" class="text-center">
            <CheckboxGroup v-model="checkScheduleIndex">
                <Checkbox class="m-t-5" v-for="(item,index) in schedules" :key="index" :label="index" border>{{item.label}}</Checkbox>
            </CheckboxGroup>
        </Row>
        <template v-else>
          <Row v-for="(item,index) in customSchduleList" :key="index" class="p-b-5">
            <i-col span="8">
              <p>
                <span class="title">开始日期</span> <DatePicker size="small" transfer class="text-black" v-model="item.startDate" :editable="false" type="date"
                  style="width: 150px" placement="bottom-start" :options="customScheduleLimit" placeholder="请选择开始档期"></DatePicker>
              </p>
              <p class="m-t-5">
                <template v-if="item.type===1">
                  <span class="title">结束日期</span> <DatePicker size="small" transfer class="text-black" v-model="item.endDate" :editable="false" type="date"
                    style="width: 150px" placement="bottom-start" :options="customScheduleLimit" placeholder="请选择结束档期"></DatePicker>

                  <p class="m-t-5"><span class="title">发布天数</span><span class="text-orange m-l-5">{{getPublishDay(item)}}</span></p>
                  <Alert v-if="getPublishDay(item)===''" type="error" class="m-t-5">结束日期不能小于开始日期</Alert>
                </template>

                <template v-else-if="item.type===2">
                  <span class="title">发布时长 </span>
                  <Select v-model="item.period" size="small" style="width:150px">
                      <Option :value="1">一周（7天）</Option>
                      <Option :value="2">四周（28天）</Option>
                      <Option :value="3">十二周（84天）</Option>
                      <Option :value="4">半年（182天）</Option>
                      <Option :value="5">一年（364天）</Option>
                  </Select>
                  <p class="m-t-5"><span class="title">结束日期</span><span class="text-orange m-l-5">{{getPublishEndDate(item)}}</span></p>
                </template>

                <template v-else>
                  <span class="title">发布天数 </span>
                  <InputNumber v-model="item.publishDay" size="small" :min="1"></InputNumber> 天
                  <p class="m-t-5"><span class="title">结束日期</span><span class="text-orange m-l-5">{{getPublishEndDateByDay(item)}}</span></p>
                </template>
                <Alert v-if="checkRepeatDate(item, index, customSchduleList)" type="error">
                    <span slot="desc">
                      发布档期中包含重复时间段，请重新设置日期！
                    </span>
                </Alert>
              </p>
            </i-col>
            <i-col span="8" class="p-t-5">
              <RadioGroup v-model="item.type">
                  <Radio :label="1">默认</Radio>
                  <Radio :label="2">固定周期</Radio>
                  <Radio :label="3">自定义天数</Radio>
              </RadioGroup>
            </i-col>
            <i-col span="8">
              <Icon type="ios-trash" color="red" size="24" class="schedule-delete" @click="handleDeleteCustomSchedule(index)"/>
            </i-col>
            <i-col span="24"><Divider style="margin: 10px 0 5px 0;" /></i-col>
          </Row>
          <a @click="handleAddCustomSchedule"><Icon type="md-add" />添加新的档期</a>

        </template>

        <div slot="footer">
            <Button type="text"  @click="modelChangeSchedule=false">取消</Button>
            <Button type="primary"  @click="ConfirmSetCopySchedule" :disabled="disabledNext">保存</Button>
        </div>
      </Modal>

      <Spin fix size="large" v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>订单加载中...</div>
      </Spin>
  </div>
</template>

<script>
import { getAvailableAdvertiserPage, getContactList } from '@/api/crm/advertiser'
import { getBrandList } from '@/api/crm/brand'
import { getOrder, copyOrder } from '@/api/order/order'
import { getProductPrice } from '@/api/order/orderprice'

import { GetCurrentSchedule, GetPrevSchedule, GetDateStr, GetPublishDay, ParseDate } from '@/utils/dateFormat'
import { buildCategoriesOrder } from '@/utils/orderUtils'

export default {
  name: 'OrderCopy',
  props: {
    platform_orderid: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      orderId: 0,
      spinShow: false,
      currentContent: 1, // 步骤标签
      orderInfo: '',
      customers: [],
      brands: [],
      contacts: [],
      orderBaseData: {
        contactId: '',
        advertiserId: '',
        brandId: '',
        remark: ''
      },
      orderRuleValidate: {
        advertiserId: [{ required: true, message: ' ' }],
        contactId: [{ required: true, message: ' ' }]
      },
      ScheduleStandard: 1,
      currentSchedules: [],
      currentCategoryId: 0,
      categories: [],
      modelChangeSchedule: false, // 弹窗
      checkScheduleProduct: null,
      scheduleTypes: [{ id: 1, name: '常规档期' }, { id: 2, name: '自定义档期' }],
      checkScheduleIndex: [],
      schedules: [], // 原始订单的档期清单

      customSchduleList: [{
        startDate: '',
        endDate: '',
        type: 1, // 自定义类型
        period: 1, // 固定周期类型
        publishDay: 1
      }],
      customScheduleLimit: {}
    }
  },
  methods: {
    initPageData () {
      if (this.platform_orderid === -1) {
        this.orderId = this.$route.params.id
      } else {
        this.orderId = this.platform_orderid
      }

      // 设置常规档期，并根据配置前移时间
      this.schedules = GetPrevSchedule(this.$store.getters.token.publisherSetting.advancePurchaseTime)

      this.initCustomOption()
      this.initOrderInfo()
      this.initOrdeProductPrice()
    },
    formatSchedule (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    initOrderInfo () {
      const query = {
        orderId: this.orderId
      }
      getOrder(query).then(res => {
        this.orderInfo = res
      }).then(() => {
        this.orderBaseData.advertiserId = this.orderInfo.advertiserId
        this.orderBaseData.remark = this.orderInfo.remark
        this.orderBaseData.contactId = this.orderInfo.contactId
        this.orderBaseData.brandId = this.orderInfo.brandId
        this.initCurrentCustomers()
      })
    },
    initCurrentCustomers () {
      this.customers = []

      const query = {
        userId: this.$store.getters.token.userInfo.userId,
        pageNumber: 1,
        pageSize: 1000
      }
      getAvailableAdvertiserPage(query).then(res => {
        this.customers = res.list.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
      }).then(() => {
        this.customerChange(0)
      })
    },
    customerChange (index) {
      if (index === 1) {
        this.orderBaseData.contactId = ''
        this.orderBaseData.brandId = ''
      }
      if (this.orderBaseData.advertiserId === '' || this.orderBaseData.advertiserId === undefined) {
        this.contacts = []
        this.brands = []
        this.orderBaseData.contactId = ''
        this.orderBaseData.brandId = ''
      } else {
        const concatequery = {
          advertiserId: this.orderBaseData.advertiserId
        }
        getContactList(concatequery).then(res => {
          this.contacts = res
          if (this.contacts.filter(x => x.id === this.orderBaseData.contactId).length === 0 && this.orderBaseData.contactId !== '') {
            this.orderBaseData.contactId = ''
          }
        })

        const brandquery = {
          advertiserId: this.orderBaseData.advertiserId,
          userId: this.$store.getters.token.userInfo.userId,
          status: '2,3,5'
        }
        getBrandList(brandquery).then(res => {
          this.brands = res
          if (this.brands.filter(x => x.id === this.orderBaseData.brandId).length === 0 && this.orderBaseData.brandId !== -1 && this.orderBaseData.brandId !== '') {
            this.orderBaseData.brandId = ''
          }
        })
      }
    },
    NextStep () { // 下一步按钮
      const _this = this
      _this.$refs.orderBaseData.validate(valid => {
        if (valid) {
          _this.orderBaseData.advertiserName = _this.customers.filter(x => x.value === _this.orderBaseData.advertiserId)[0].label
          _this.orderBaseData.brandName = _this.orderBaseData.brandId ? _this.brands.filter(x => x.id === _this.orderBaseData.brandId)[0].name : '-'
          _this.orderBaseData.contactName = _this.contacts.filter(x => x.id === _this.orderBaseData.contactId)[0].name
          _this.currentContent = 2
        }
      })
    },
    initOrdeProductPrice () {
      getProductPrice({ orderId: this.orderId }).then(res => {
        // 获取常规采购的产品分类
        this.categories = []
        const productCategories = buildCategoriesOrder(res.filter(x => x.priceType === 1))
        let productSchedule

        productCategories.forEach(item => {
          productSchedule = {
            category: item.category,
            categoryId: item.categoryId,
            schedules: item.schedules.map(x => {
              return {
                productItem: x,
                oldSchedule: { startDate: x[0].startDate, endDate: x[0].endDate },
                newSchedules: [],
                checkProducts: []
              }
            })
          }
          this.categories.push(productSchedule)
        })
      })
    },
    handleChangeCopySchedule (categoryId, scheduleProduct) {
      this.currentCategoryId = categoryId
      this.checkScheduleProduct = scheduleProduct
      this.modelChangeSchedule = true
    },
    ConfirmSetCopySchedule () {
      const newSchedule = []
      if (this.ScheduleStandard === 1) { // 常规
        this.checkScheduleIndex.forEach(res => {
          newSchedule.push({ startDate: this.schedules[res].value, endDate: GetDateStr(this.schedules[res].value, 14) })
        })
      } else { // 自定义
        this.customSchduleList.forEach(res => {
          if (res.startDate !== '' && res.endDate !== '') {
            newSchedule.push({ startDate: ParseDate(res.startDate), endDate: ParseDate(res.endDate) })
          }
        })
      }

      // 给当前categories重新赋值
      this.categories.forEach(category => {
        // 找到对应的category
        if (category.categoryId === this.currentCategoryId) {
          category.schedules.forEach(item => {
            if (item.oldSchedule === this.checkScheduleProduct.oldSchedule) {
              item.newSchedules = newSchedule
            }
          })
        }
      })

      this.modelChangeSchedule = false
    },
    CancelCopySchedule (categoryId, scheduleProduct) {
      // 给当前categories重新赋值
      this.categories.forEach(category => {
        // 找到对应的category
        if (category.categoryId === categoryId) {
          category.schedules.forEach(item => {
            if (item.oldSchedule === scheduleProduct.oldSchedule) {
              item.newSchedules = []
            }
          })
        }
      })
    },
    ConfirmCopyOrder () {
      const _this = this

      _this.$Modal.confirm({
        title: '确定提交',
        content: '您将要提交复制订单请求，请确认您已经完善复制信息，是否继续？',
        loading: true,
        onOk: () => {
          _this.spinShow = true
          const data = {
            orderId: _this.orderId,
            advertiserId: _this.orderBaseData.advertiserId,
            brandId: _this.orderBaseData.brandId === '-1' ? null : _this.orderBaseData.brandId,
            contactId: _this.orderBaseData.contactId,
            remark: _this.orderBaseData.remark,
            content: ''
          }
          const content = []
          _this.categories.forEach(element => {
            element.schedules.forEach(item => {
              // 查找到已勾选的产品
              item.checkProducts.forEach(productId => {
                content.push({
                  productId: productId,
                  oldStartDate: item.oldSchedule.startDate,
                  oldEndDate: item.oldSchedule.endDate,
                  schedules: item.newSchedules.length > 0 ? item.newSchedules : [{ startDate: item.oldSchedule.startDate, endDate: item.oldSchedule.endDate }]
                })
              })
            })
          })
          data.content = JSON.stringify(content)
          copyOrder(data).then(res => {
            _this.spinShow = false
            if (res) {
              const query = { orderId: res }
              this.$store.commit('set_order_ID', res)
              this.$store.dispatch('getOrderBaseInfo', query)
              this.$store.commit('set_order_pageType', 'edit')
            } else {
              if (_this.orderBaseData.brandId === null) {
                _this.orderBaseData.brandId = '-1'
              }
            }
          }).catch(() => {
            _this.spinShow = false
          })
          _this.$Modal.remove()
        }
      })
    },
    initCustomOption () {
      const _this = this
      this.customScheduleLimit = {
        disabledDate (date) {
          return date.valueOf() < Date.now() - (86400000 * 30 * _this.$store.getters.token.publisherSetting.advancePurchaseTime)
        }
      }
    },
    handleAddCustomSchedule () {
      this.customSchduleList.push({
        startDate: '',
        endDate: '',
        type: 1, // 自定义类型
        period: 1, // 固定周期类型
        publishDay: 1
      })
    },
    handleDeleteCustomSchedule (index) {
      this.customSchduleList.splice(index, 1)
    },
    getPublishDay (schedule) {
      if (schedule.startDate === '' || schedule.endDate === '') {
        return '-'
      } else {
        return GetPublishDay(schedule.startDate, schedule.endDate)
      }
    },
    getPublishEndDate (schedule) {
      if (schedule.startDate === '') {
        return '-'
      } else {
        let endDate = ''
        if (schedule.period === 1) { // 一周
          endDate = GetDateStr(schedule.startDate, 7)
        } else if (schedule.period === 2) { // 四周
          endDate = GetDateStr(schedule.startDate, 28)
        } else if (schedule.period === 3) { // 十二周
          endDate = GetDateStr(schedule.startDate, 84)
        } else if (schedule.period === 4) { // 半年
          endDate = GetDateStr(schedule.startDate, 182)
        } else if (schedule.period === 5) { // 一年
          endDate = GetDateStr(schedule.startDate, 364)
        }

        schedule.endDate = endDate
        return endDate
      }
    },
    getPublishEndDateByDay (schedule) {
      if (schedule.startDate === '') {
        return '-'
      } else {
        const endDate = GetDateStr(schedule.startDate, schedule.publishDay)
        schedule.endDate = endDate
        return endDate
      }
    },
    checkRepeatDate (item, itemIndex, itemList) { // 验证自定义日期是否有重复
      // const isOverlap = (StartA, EndA, StartB, EndB) => {
      //   const getT = (t) => new Date(t)
      //   return !(getT(EndA) <= getT(StartB) || getT(StartA) >= getT(EndB))
      // }

      // if (itemList.length < 2) {
      //   return false
      // } else {
      //   // 循环验证是否重复
      //   let flag = false
      //   for (let index = 0; index < itemList.length; index++) {
      //     if (index !== itemIndex && itemList[index].startDate && itemList[index].endDate && item.startDate && item.endDate) {
      //       flag = isOverlap(item.startDate, item.endDate, itemList[index].startDate, itemList[index].endDate)
      //       if (flag) {
      //         break
      //       }
      //     }
      //   }

      //   return flag
      // }

      return false
    }
  },
  computed: {
    disabledNext: function () {
      if (this.ScheduleStandard === 1) {
        return this.checkScheduleIndex.length === 0
      } else {
        let flag = true
        for (let index = 0; index < this.customSchduleList.length; index++) {
          if (this.customSchduleList[index].startDate !== '' && this.customSchduleList[index].endDate !== '' && GetPublishDay(this.customSchduleList[index].startDate, this.customSchduleList[index].endDate) !== '') {
            flag = this.checkRepeatDate(this.customSchduleList[index], index, this.customSchduleList)
            break
          }
        }

        return flag
      }
    },
    Btndisabled () {
      let count = 0
      this.categories.forEach(element => {
        element.schedules.forEach(item => {
          count += item.checkProducts.length
        })
      })

      return count === 0
    }
  },
  created () {
    this.initPageData()
  },
  watch: {
    platform_orderid (val) {
      this.initPageData()
    }
  }

}
</script>
